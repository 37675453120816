import React from "react"
import { ListGroup } from "reactstrap"
import { PropTypes } from "prop-types"
import classnames from "classnames"

export default class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props)

    // Sets up our initial state
    this.state = {
      error: false,
      isLoading: false,
      isUnmount: false
    }
    this.onScrollEvent = this.onScrollEvent.bind(this)
  }

  async onScrollEvent(event) {
    try {
      const { error, isLoading } = this.state
      const { hasMore, next } = this.props
      // console.log(window.innerHeight, document.documentElement.scrollTop, document.documentElement.offsetHeight)
      let { scrollHeight, scrollTop, clientHeight } = event.target
      if (Number(scrollTop.toFixed()) + Number(clientHeight.toFixed()) !== Number(scrollHeight.toFixed()) || !hasMore || isLoading)
        return
      this.mutateWhenMount({ isLoading: true })
      await next()
      this.mutateWhenMount({ isLoading: false })
    } catch (err) {
      this.mutateWhenMount({
        error: true
      })
    }
  }

  async mutateWhenMount(state, _callback) {
    try {
      if (!this.state.isUnmount)
        this.setState(state, () => _callback && _callback)
      return true
    } catch (err) {
      return false
    }
  }

  componentWillUnmount() {
    this.state.isUnmount = true
  }

  render() {
    const { error, isLoading } = this.state

    const {
      component: Tag,
      style,
      className,
      loader: Loader,
      children
    } = this.props
    return (
      <Tag
        style={style}
        className={classnames("he-100 overflow-auto", className)}
        onScroll={this.onScrollEvent}
      >
        {!error ? [children, !isLoading ? null : Loader] : null}
      </Tag>
    )
  }
}

InfiniteScroll.defaultProps = {
  children: null,
  next: () => false,
  hasMore: false,
  loader: <h3 key={1}>Loading....</h3>,
  dataLength: 0,
  component: ListGroup,
  style: {},
  className: ""
}

InfiniteScroll.propTypes = {
  children: PropTypes.node,
  next: PropTypes.func,
  hasMore: PropTypes.bool,
  loader: PropTypes.node,
  dataLength: PropTypes.number,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  style: PropTypes.object,
  className: PropTypes.string
}
