import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withAuth } from "./Auth";

const RouteAuthenticated = ({
  component: Component,
  render,
  auth,
  isAuthenticated,
  fetchMenu,
  fetchText,
  ...res
}) => {
  const [isError, setError] = React.useState(false);


  if (!isAuthenticated) {
    return <Redirect from="/:any" to="/" />;
  }

  if (isError) {
    return (
      <div>
        error occured when fetching data
        <span>
          <button onClick={_ => window.location.reload()}>try again ?</button>
        </span>
      </div>
    );
  }

  return (
    <Route
      {...res}
      render={render ? render : props => <Component {...props} />}
    />
  );
};

const Home = ({ component: Component, render, auth, isAuthenticated, ...res }) => {
  if (isAuthenticated) {
    return <Redirect from="/:any" to="/home" />;
  }
  return (
    <Route
      {...res}
      render={render ? render : props => <Component {...props} />}
    />
  );
};

const RouteHome = withAuth(Home);

export default withAuth(RouteAuthenticated);
export { RouteHome };
