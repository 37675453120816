import React from "react"
import { toast } from "react-toastify"

function toastify(
  type,
  message,
  _onCloceCallback,
  userConfig = {}
) {
  const config = {
    containerId: 'mainToast',
    draggable: false,
    position: "top-center",
    autoClose: 5000,
    ...userConfig // see props on github https://github.com/fkhadra/react-toastify#toastcontainer
  }
  if (type) {
    config.type = type
  }
  if (_onCloceCallback) {
    config.onClose = () => _onCloceCallback()
  }
  return toast(({closeToast}) => message, config)
}

export default toastify
