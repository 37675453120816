import React from "react"
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Input,
    FormFeedback,
    Label
} from "reactstrap"
import { useFormik } from "formik"
import { Api, ResponseError, ResponseSuccess, toastify } from "../libraries"
import * as Yup from "yup"
import PropTypes from "prop-types"

const ModalCrud = React.memo(
    ({ isOpen, action, data, toggle, fetchGames }) => {
        var gameAction = {
            createGame: function(values) {
                return Api({ withAuth: true }).post("/games", {
                    ...values
                })
                .then(response => {
                    const result = ResponseSuccess(response)
                    toastify("success", "Game create successfull")
                    toggleOpen()
                    fetchGames()
                })
                .catch(response => {
                    const result = ResponseError(response)
                    toastify("error", result?.data?.message instanceof Object ? "Terjadi Kesalahan" : result?.data?.message || "Terjadi Kesalahan")
                })
            },
            updateGame: function(values, id) {
                return Api({ withAuth: true }).put(`/games/${id}`, {
                    ...values
                })
                .then(response => {
                    const result = ResponseSuccess(response)
                    toastify("success", "Game update successfull")
                    toggleOpen()
                    fetchGames()
                })
                .catch(response => {
                    const result = ResponseError(response)
                    toastify("error", result?.data?.message instanceof Object ? "Terjadi Kesalahan" : result?.data?.message || "Terjadi Kesalahan")
                })
            }
        }
        const formik = useFormik({
            enableReinitialize: true,
            initialValues: {
                name: data?.name ?? "",
                author: data?.author ?? "",
                version: data?.version ?? 0,
                link: data?.link ?? "",
                action: data?.action ?? "",
                status: data?.status ?? ""
            },
            validationSchema: Yup.object({
                name: Yup.string().required("game name can't be empty!"),
                author: Yup.string().required("game author can't be empty!"),
                link: Yup.string().url("game link must be valid url!"),
                version: Yup.number().required("game version can't empty!"),
                action: Yup.string()
                .required("Play status can't be empty!")
                .oneOf(
                    ["Played", "Onhold", "Abandoned"],
                    "Play status was invalid!"
                ),
                status: Yup.string()
                .required("Development status can't be empty!")
                .oneOf(
                    ["Completed", "Onhold", "Active", "Abandoned"],
                    "Development status was invalid!"
                )
            }),
            onSubmit: async (values, { setSubmitting }) => {
                if (!action) {
                    setSubmitting(false);
                    return;
                }
                gameAction[`${action}Game`](values, data._id)
            }
        });

        function toggleOpen() {
            toggle("")
            formik.resetForm()
        }
        return (
            <Modal
                toggle={toggleOpen}
                isOpen={isOpen}
                backdrop="static"
                centered
                scrollable
            >
                <ModalHeader
                    className="text-capitalize"
                    toggle={toggleOpen}
                >
                    {action} Game
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={formik.handleSubmit}>
                        <FormGroup className="mb-3">
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                placeholder="game name"
                                invalid={formik.touched?.name && !!formik.errors?.name}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={formik.isSubmitting}
                            />
                            <FormFeedback>{formik.errors?.name}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="author">Author</Label>
                            <Input
                                type="text"
                                name="author"
                                placeholder="game author"
                                invalid={formik.touched?.author && !!formik.errors?.author}
                                value={formik.values.author}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={formik.isSubmitting}
                            />
                            <FormFeedback>{formik.errors?.author}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="password">Version</Label>
                            <Input
                                type="number"
                                name="version"
                                placeholder="game version"
                                invalid={formik.touched?.version && !!formik.errors?.version}
                                value={formik.values.version}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={formik.isSubmitting}
                            />
                            <FormFeedback>{formik.errors?.version}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="link">Link</Label>
                            <Input
                                type="url"
                                name="link"
                                placeholder="game reference url"
                                invalid={formik.touched?.link && !!formik.errors?.link}
                                value={formik.values.link}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={formik.isSubmitting}
                            />
                            <FormFeedback>{formik.errors?.link}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="playStatus">Play Status</Label>
                            <Input
                                type="select"
                                name="playStatus"
                                id="playStatus"
                                placeholder="your play status"
                                invalid={formik.touched?.action && !!formik.errors?.action}
                                value={formik.values.action}
                                onChange={e => formik.setFieldValue("action", e.target.value)}
                                onBlur={formik.handleBlur}
                                disabled={formik.isSubmitting}
                            >
                                <option value="" hidden>Select...</option>
                                <option value="Played">Played</option>
                                <option value="Onhold">Onhold</option>
                                <option value="Abandoned">Abandoned</option>
                            </Input>
                            <FormFeedback>{formik.errors?.action}</FormFeedback>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="developmentStatus">Development Status</Label>
                            <Input
                                type="select"
                                name="developmentStatus"
                                id="developmentStatus"
                                placeholder="game development status"
                                invalid={formik.touched?.status && !!formik.errors?.status}
                                value={formik.values.status}
                                onChange={e => formik.setFieldValue("status", e.target.value)}
                                onBlur={formik.handleBlur}
                                disabled={formik.isSubmitting}
                            >
                                <option value="" hidden>Select...</option>
                                <option value="Completed">Completed</option>
                                <option value="Onhold">Onhold</option>
                                <option value="Active">Active</option>
                                <option value="Abandoned">Abandoned</option>
                            </Input>
                            <FormFeedback>{formik.errors?.status}</FormFeedback>
                        </FormGroup>
                        <button type="submit" hidden></button>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={toggleOpen}>Cancel</Button>
                    <Button color="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>Submit</Button>
                </ModalFooter>
            </Modal>
        )
    }
)


ModalCrud.defaultProps = {
    isOpen: false,
    action: "",
    data: {},
    toggle: () => false
}

ModalCrud.propTypes = {
    isOpen: PropTypes.bool,
    action: PropTypes.string,
    data: PropTypes.object,
    toggle: PropTypes.func
}

export default ModalCrud