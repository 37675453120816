import React from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    Row
} from "reactstrap";
import { ResponseError, ResponseSuccess, Api, toastify, withAuth } from "../libraries";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const Login = ({ setAuth, isAuthenticated }) => {
    const history =useHistory();

    const formik = useFormik({
        initialValues: {
          username: "",
          password: ""
        },
        validationSchema: Yup.object({
          username: Yup.string().required("Username tidak boleh kosong!"),
          password: Yup.string().required("Password tidak boleh kosong!")
        }),
        onSubmit: (values, { setSubmitting }) => {
          Api().post('/auth/login', {
            username: values.username,
            password: values.password
          })
          .then(response => {
              const result = ResponseSuccess(response);
              setAuth(result?.data?.token)
              history.push('/home')
          })
          .catch(response => {
              const result = ResponseError(response);
              toastify("error", result?.data?.message instanceof Object ? "Terjadi Kesalahan" : result?.data?.message ?? "Terjadi Kesalahan")
          })
          .finally(response => {
            setSubmitting(false);
          })
        }
    });

    return (
        <Container className="d-flex flex-column justify-content-center" style={{
            height: '100vh'
        }}>
            <Row className="justify-content-center">
                <Col xs={12} sm={8} lg={4}>
                    <Card className="p-4">
                        <CardBody className="p-0">
                            <Form onSubmit={formik.handleSubmit}>
                                <FormGroup className="mb-3">
                                    <InputGroup>
                                    <Input
                                        type="text"
                                        name="username"
                                        placeholder="Username"
                                        invalid={
                                        formik.touched?.username && !!formik.errors?.username
                                        }
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                    />
                                    <FormFeedback>{formik.errors?.username}</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="mb-4">
                                    <InputGroup>
                                    <Input
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        value={formik.values.password}
                                        invalid={
                                        formik.touched?.password && !!formik.errors?.password
                                        }
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={formik.isSubmitting}
                                    />
                                    <FormFeedback>{formik.errors?.password}</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <Button
                                    color="primary"
                                    className="px-4 w-100 text-uppercase"
                                    disabled={formik.isSubmitting}
                                >
                                    Login
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default withAuth(Login);
