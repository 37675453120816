import React from "react"
import withContext from "./withContext"

const { Consumer, Provider } = React.createContext()

class AuthProvider extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isUnmount: false,
        token: localStorage.getItem("mygames:auth")
      }
      this.setAuth = this.setAuth.bind(this)
    }
  
    componentWillUnmount() {
      this.state.isUnmount = true
    }
  
    async setWhenMount(state, callback) {
      try {
        if (!this.state.isUnmount) {
          return await this.setState(state, callback && callback)
        }
      } catch(err) {
        console.error("KontakInternalProvider => setWhenMount")
        console.error(err)
      }
    }

    async setAuth(auth) {
        await this.setWhenMount(prevState => ({
            ...prevState,
            token: auth
        }))
        if (auth)
          localStorage.setItem("mygames:auth", auth)
        else
          localStorage.removeItem("mygames:auth")
    }
  
    render() {
      const { children, ...resProps } = this.props
      const isAuthenticated = !!this.state.token
      return (
        <Provider value={{
            isAuthenticated,
            setAuth: this.setAuth,
          ...resProps
        }}>
          {children}
        </Provider>
      )
    }
}

const withAuth = (Component = <div/>) => {
    withAuth.displayName = `withAuth(${Component.displayName || Component.name || 'Component'})`
    return withContext(Component, Consumer)
}

export default AuthProvider
export { withAuth }