import axios from "axios"

const success = res => {
  return {
    status: true,
    statusCode: res.status,
    statusText: res.statusText,
    headers: res.headers,
    data: res.data
  }
}

const error = res => {
  let data = {}

  if (res.response) {
    data = {
      status: false,
      statusCode: res.response.status,
      statusText: res.response.status.statusText,
      headers: res.response.headers,
      data: res.response.data
    }
  } else if (res.request) {
    data = {
      status: false,
      statusCode: 0,
      statusText: res.request,
      headers: res.request.headers,
      data: { message: "Koneksi bermasalah" }
    }
  } else {
    data = {
      status: false,
      statusCode: 0,
      statusText: res.message,
      headers: res.headers,
      data: { message: res.message }
    }
  }

  return data
}

const headerData = ({ token, ...data } = {}) => {
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...data
  }
  if (token) {
    header["Authorization"] = `Bearer ${token}`
  }

  return header
}

let cancelRequest

const Api = ({ headers, withAuth = false, ...axiosConfig } = {}) => {
  const CancelToken = axios.CancelToken

  const instance = axios.create({
    baseURL: "https://peaceful-earth-10879.herokuapp.com/api",
    headers: headerData(headers),
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancelRequest = c
    }),
    ...axiosConfig
  })

  instance.interceptors.request.use(
    async request => {
      if (withAuth) {
        const auth = localStorage.getItem("mygames:auth")
        if (!auth) {
          return request
        }
        request.headers = headerData({ token: auth })
      }

      return request
    },
    function(error) {
      return Promise.reject(error)
    }
  )

  return instance
}

export default Api
export { cancelRequest as CancelRequest }
export { success as ResponseSuccess }
export { error as ResponseError }
