import React, { Component } from 'react';
import { RouteAuthenticated, RouteHome, Api } from "../libraries";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./login";
import Home from "./home";


class App extends Component {
  render() {
    return (
      <React.Fragment>
          <ToastContainer containerId="mainToast" style={{zIndex: 9999}} />
          <Switch>
            <RouteAuthenticated
              path="/home"
              name="Home"
              render={props => <Home {...props} />}
            />
            <RouteHome path="/" exact name="root" component={Login} />
            <Route
              exact
              name="404"
              render={props => <Redirect to="/home" />}
            />
          </Switch>
      </React.Fragment>
    );
  }
}

export default App;
