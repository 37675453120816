import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom"
import { AuthProvider } from "./libraries"
import './scss/style.scss';
import App from './views/App';
import * as serviceWorker from './serviceWorker';

function Main() {
    return (
        <Router>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </Router>
    )
}


ReactDOM.render(<Main/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
