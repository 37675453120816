import React from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Button,
    Input,
    Container,
    CustomInput,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText
} from "reactstrap";
import { ResponseError, ResponseSuccess, Api, toastify, withAuth, InfiniteScroll } from "../libraries";
import { useHistory } from "react-router-dom";
import Modal from "./modal"

let timeout;

const Home = ({ setAuth, isAuthenticated }) => {
    const history = useHistory();
    const [games, setGames] = React.useState([]);
    const [filterize, setFilterize] = React.useState({
        page: 1,
        limit: 20,
        count: 0,
        hasMore: true
    });
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [selectedItems, setSelected] = React.useState([]);
    const [detailItem, setDetailItem] = React.useState({});
    const [modalOpen, setModalOpen] = React.useState("");

    const toggleModal = React.useCallback((modal, detail = {}) => {
        setModalOpen(modal)
        setDetailItem(detail)
    }, [modalOpen]);

    const handleSearchChange = event => {
        const value = event.target.value
        setFilterize(state => ({
            ...state,
            search: `like:${value}`,
            page: 1
        }))
        clearTimeout(timeout)
        timeout = setTimeout(() => fetchGames({ search: `like:${value}`, page: 1 }).then(response => {
            if (!response.status || response.data.status !== "ok") {
                return;
            }
            setGames(response?.data?.data?.items)
        }), 1000)
    }

    function handleSelectChange(data = []) {
        let items = selectedItems.slice()
        if (data.length === 1) {
            const isItemExist = items.indexOf(data[0]);
            if (isItemExist < 0) {
                items.push(data[0])
            } else {
                items.splice(isItemExist, 1)
            }
        } else {
            if (data.length === selectedItems.length) {
                items = []
            } else {
                items = data
            }
        }
        setSelected(items)
    }

    function logout() {
        Api({ withAuth: true }).post('/auth/logout')
        .then(response => {
            setAuth(null)
            history.push('/')
        })
        .catch(response => {
            const result = ResponseError(response)
            toastify("error", result?.data?.message instanceof Object ? "Terjadi Kesalahan" : result?.data?.message ?? "Terjadi Kesalahan")
        })
    }

    function fetchGames(filter = {}) {
        return Api({ withAuth: true }).get('/games', {
            params: {
                ...filterize,
                ...filter
            }
        })
        .then(response => {
            const result = ResponseSuccess(response)
            const total = filterize.count + result?.data?.data?.current;
            setFilterize(prevState => ({
                ...prevState,
                count: total,
                page: prevState.page + 1,
                hasMore: total !== result?.data?.data?.total
                // hasMore: true
            }))
            return result;
        })
        .catch(response => {
            const result = ResponseError(response)
            toastify("error", result?.data?.message instanceof Object ? "Terjadi Kesalahan" : result?.data?.message ?? "Terjadi Kesalahan")
            return result
        })
    }

    function fetchDelete() {
        return Api({ withAuth: true }).delete('/games', {
            data: {data: selectedItems}
        })
        .then(response => {
            const result = ResponseSuccess(response)
            toastify("success", "Delete games successfull")
            fetchGames({ page: 1 }).then(response => {
                if (!response.status || response.data.status !== "ok") {
                    return;
                }
                setGames(response?.data?.data?.items)
                setSelected([])
            })
        })
        .catch(response => {
            const result = ResponseError(response)
            toastify("error", result?.data?.message instanceof Object ? "Terjadi Kesalahan" : result?.data?.message || "Terjadi Kesalahan")
        })
    }

    React.useEffect(() => {
        setTimeout(() => fetchGames().then(response => {
            if (!response.status || response.data.status !== "ok") {
                return;
            }
            setGames(response?.data?.data?.items)
        }), 3000)
    }, [])

    return (
        <div style={{ height: '100vh' }} className="d-flex flex-column">
            <Navbar color="dark" dark expand="md">
                <NavbarBrand href="/home">My Games</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Button className="ml-md-auto" onClick={logout}>Logout</Button>
                </Collapse>
            </Navbar>
            <Container fluid>
                <div className="w-100 my-3">
                    <Input
                        placeholder="Search your game..."
                        onChange={event => handleSearchChange(event)}
                    />
                </div>
                <div id="data-control" className="d-inline-flex align-items-center">
                    <CustomInput
                        id="isAll"
                        type="checkbox"
                        name="checkAll"
                        checked={games.length && games.length === selectedItems.length}
                        onChange={_ => handleSelectChange(games.map(game => game._id))}
                    />
                    {selectedItems.length ? (
                        <Button color="danger" onClick={_ => fetchDelete()}>Hapus</Button>
                    ) : null}
                    {!selectedItems.length ? (
                        <Button color="primary" onClick={_ => toggleModal("create")}>Tambah</Button>
                    ) : null}
                </div>
                <InfiniteScroll
                    style={{
                        overflowY: "auto",
                        height: 500
                    }}
                    className="my-3"
                    hasMore={filterize.hasMore}
                    next={() => fetchGames().then(response => {
                        if (!response.status || response.data.status !== "ok") {
                            return;
                        }
                        setGames(prevState => ([
                            ...prevState,
                            ...response?.data?.data?.items
                        ]))
                    })}
                >
                    {games == 0 ? (
                        <div>empty...</div>
                    ) : games.map(game => (
                        <ListGroupItem key={game._id} className="d-flex align-items-center">
                            {selectedItems.length ? (
                                <span>
                                    <CustomInput
                                        type="checkbox"
                                        id={game._id}
                                        checked={selectedItems.includes(game._id)}
                                        onChange={_ => handleSelectChange([game._id])}
                                    />
                                </span>
                            ) : null}
                            <span>
                                <ListGroupItemHeading>
                                    {game.name}
                                </ListGroupItemHeading>
                                <ListGroupItemText className="mb-0">
                                    Author: {game.author}
                                </ListGroupItemText>
                                <ListGroupItemText className="mb-0">
                                    Version: {game.version}
                                </ListGroupItemText>
                                <ListGroupItemText className="mb-0">
                                    Development Status: {game.status}
                                </ListGroupItemText>
                                <ListGroupItemText className="mb-0">
                                    Play Status: {game.action}
                                </ListGroupItemText>
                                <ListGroupItemText className="mb-0">
                                    Link: <span className="btn-link" href={game.link} onClick={_ => window.open(game.link)}>Detail</span>
                                </ListGroupItemText>
                            </span>
                            {!selectedItems.length ? (
                                <span className="ml-auto">
                                    <Button color="primary" onClick={_ => toggleModal("update", game)}>Edit</Button>
                                </span>
                            ) : null}
                        </ListGroupItem>
                    ))}
                </InfiniteScroll>
            </Container>
            <Modal
                isOpen={!!modalOpen}
                action={modalOpen}
                toggle={toggleModal}
                data={detailItem}
                fetchGames={() => fetchGames({ page: 1 }).then(response => {
                    if (!response.status || response.data.status !== "ok") {
                        return;
                    }
                    setGames(response?.data?.data?.items)
                })}
            />
        </div>
    )
}

export default withAuth(Home);
